@import 'import';
.Balloon {
  $coefficient: 0.7071;
  $side-margin: calc(var(--beak-size) + var(--beak-target-offset));
  $side-offset: calc(-#{$coefficient} * var(--beak-size));
  $align-margin: calc(-1 * var(--beak-edge-extra-offset));
  $align-start: calc(0.5 * var(--target-size) - #{$coefficient} * var(--beak-size) + var(--beak-edge-extra-offset));
  $align-center: calc(50% - var(--beak-size) + calc(#{1 - $coefficient} * var(--beak-size)));
  $align-end: calc(
    100% - 0.5 * var(--target-size) - #{$coefficient} * var(--beak-size) - var(--beak-edge-extra-offset)
  );

  --beak-edge-extra-offset: #{rem(0)};
  --beak-target-offset: #{rem(6)};
  --beak-size: #{rem(10)};
  --target-size: #{rem(20)};

  position: relative;
  background-color: $white;
  border-radius: rem(4);

  &::before {
    content: '';

    position: absolute;
    z-index: -1;
    inset: 0;

    border-radius: rem(4);
    box-shadow: 0 rem(3) rem(12) 0 rgba($black-deep, 0.17);
  }

  &::after {
    content: '';

    position: absolute;
    z-index: -1;
    transform: rotate(45deg);

    border: calc(#{$coefficient} * var(--beak-size)) solid transparent;
    box-shadow: 0 rem(3) rem(12) 0 rgba($black-deep, 0.17);
  }

  &._left {
    margin-left: $side-margin;

    &::after {
      left: $side-offset;
      border-color: transparent transparent $white $white;
    }
  }

  &._right {
    margin-right: $side-margin;

    &::after {
      right: $side-offset;
      border-color: $white $white transparent transparent;
    }
  }

  &._left,
  &._right {
    &._start {
      margin-top: $align-margin;

      &::after {
        top: $align-start;
      }
    }

    &._center::after {
      top: $align-center;
    }

    &._end {
      margin-bottom: $align-margin;

      &::after {
        top: $align-end;
      }
    }
  }

  &._top {
    margin-top: $side-margin;

    &::after {
      top: $side-offset;
      border-color: $white transparent transparent $white;
    }
  }

  &._bottom {
    margin-bottom: $side-margin;

    &::after {
      bottom: $side-offset;
      border-color: transparent $white $white transparent;
    }
  }

  &._top,
  &._bottom {
    &._start {
      margin-left: $align-margin;

      &::after {
        left: $align-start;
      }
    }

    &._center::after {
      left: $align-center;
    }

    &._end {
      margin-right: $align-margin;

      &::after {
        left: $align-end;
      }
    }
  }
}
