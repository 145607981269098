@import 'import';
.ContextModal {
  position: absolute;
  z-index: 1;
  top: var(--context-modal-y, 0);
  left: var(--context-modal-x, 0);

  &:not(._opened) {
    display: none;
  }
}
